.container {
  background-color: #EAECF780;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 18px;
  border-left: 10px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 414px;
}

.content {
  display: flex;
  flex-direction: row;
}

.box {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 60px;
}