@import '../../../../constants/Colors.css';

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-accent);
  border-radius: 10px;
  padding: 4px 26px;
  color: white;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.14);
  gap: 8px;
}

.buttonContainer:hover {
  opacity: 0.8;
  cursor: pointer;
}
