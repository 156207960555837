@import '../../../constants/Colors.css';

.view {
  flex-direction: row;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.view:hover {
  cursor: pointer;
}
