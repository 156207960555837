@import '../../../../../constants/Colors.css';

.container {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 12px;
  align-items: center;
}

.textContainer {
  flex: 1;
  flex-direction: column;
}

.title {
  font-size: 12px;
  font-weight: bold;
  color: var(--color-dark-text);
}

.description {
  font-size: 12px;
  color: var(--color-light-text);
}

.colorContainer {
  display: flex;
  flex: 0.8;
  flex-direction: row;
  height: 36px;
  border: 1px solid darkgray;
  border-radius: 6px;
  overflow: hidden;
}

.color {
  flex: 1;
}

.pickerIcon {
  width: 36px;
  background-color: var(--color-layout-container-bg);
  border-left: 1px solid darkgray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
