@import '../../constants/Colors.css';

.container {
  width: 100%;
  height: 100%;
}

.layout {
  min-height: 100vh;
}

.layoutHeader {
  display: flex;
  background-color: var(--color-header-bg);
  padding: 0px;
}

.layoutSider {
  color: white;
  background-color: var(--color-slider-bg);
  border-right: 1px solid #ddd;
}

.menuItem {
  padding: 0px;
  margin-top: -4px;
  color: white;
  background-color: var(--color-accent);
  font-weight: bold;
  font-size: 14px;
  border-radius: 0px;
  /* text-transform: uppercase; */
}

.menuItem :hover {
  color: var(--color-accent);
}


.subSiderItem {
  background-color: var(--color-slider-bg);
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 36px;
  padding-bottom: 20px;
  padding-top: 8px;
  font-size: 12px;
  min-height: 24px;
}

.siderItem {
  background-color: var(--color-slider-bg);
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding: 10px 16px;
  font-size: 14px;
  min-height: 42px;
}

.siderItem :hover {
  color: var(--color-accent);
}

.siderItemActive {
  color: var(--color-accent);
}

.layoutContent {
  color: #999;
  background-color: #f2f7fb;
}

.pageTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 32px;
  padding-top: 16px;
}

.pageTitle {
  color: var(--color-header-text);
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}

.icon {
  max-width: 14px;
  height: auto;
  object-fit: cover;
}

.screenBlocker {
  background-color: rgba(0, 0, 0, 0.45);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  z-index: 9;
}

.disabledLink {
  pointer-events: none;
}

.centeredText {
    text-align: 'center'
}

.modalButton {
  display: flex;
  justify-content: right;
}

.closeButton {
  border-radius: 5px;
  background-color: var(--color-accent);
  border-color: var(--color-accent);
  margin-top: 20px;
}
