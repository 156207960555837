@font-face {
  font-family: 'icomoon';
  src: url('/fonts/icomoon.eot?rw605l');
  src: url('/fonts/icomoon.eot?rw605l#iefix') format('embedded-opentype'),
    url('/fonts/icomoon.ttf?rw605l') format('truetype'),
    url('/fonts/icomoon.woff?rw605l') format('woff'),
    url('/fonts/icomoon.svg?rw605l#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bed_bed_room_sleep_furniture_icon_124423:before {
  content: '\e918';
}
.icon-signature_regular_icon_203276:before {
  content: '\e919';
}
.icon-hotel-signal-of-capital-letter_icon-iconscom_70710:before {
  content: '\e91a';
}
.icon-appointment-calendar-communication-contact-date-iconoteka-personal_107668:before {
  content: '\e91b';
}
.icon-fin-available-calendar-date-day-event-iconoteka-schedule_107694:before {
  content: '\e91c';
}
.icon-check_mark_ok_good_approve_safe_icon-iconscom_59987:before {
  content: '\e91d';
}
.icon-mail_message_envelope_letter_icon_192475:before {
  content: '\e912';
}
.icon-llegada-available-calendar-date-day-event-iconoteka-schedule_107694:before {
  content: '\e91e';
}
.icon-available-calendar-date-day-event-iconoteka-schedule_107694:before {
  content: '\e91f';
}
.icon-user_people_male_avatar_man_icon_196478-copia:before {
  content: '\e920';
}
.icon-business-card-finance-iconoteka-membership-payment_107686:before {
  content: '\e921';
}
.icon-share:before {
  content: '\e901';
}
.icon-information-1:before {
  content: '\e990';
}
.icon-next:before {
  content: '\e90f';
}
.icon-birthday-cake:before {
  content: '\e909';
}
.icon-calendar:before {
  content: '\e906';
}
.icon-close:before {
  content: '\e915';
}
.icon-accept:before {
  content: '\e914';
}
.icon-checked:before {
  content: '\e90c';
}
.icon-credit-card:before {
  content: '\e908';
}
.icon-delete:before {
  content: '\e90e';
}
.icon-download:before {
  content: '\e913';
}
.icon-fat-boy-smiling:before {
  content: '\e903';
}
.icon-flag:before {
  content: '\e907';
}
.icon-hotel1:before {
  content: '\e917';
}
.icon-back:before {
  content: '\e910';
}
.icon-location:before {
  content: '\e904';
}
.icon-moon:before {
  content: '\e905';
}
.icon-hotel-not:before {
  content: '\e916';
}
.icon-pen:before {
  content: '\e90d';
}
.icon-phone:before {
  content: '\e90b';
}
.icon-pin:before {
  content: '\e911';
}
.icon-search:before {
  content: '\e900';
}
.icon-sex:before {
  content: '\e90a';
}
.icon-child:before {
  content: '\e902';
}
.icon-upload:before {
  content: '\912';
}
