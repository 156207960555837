@import '../../../../../constants/Colors.css';

.container {
  background-color: var(--color-accent);
  flex: 1;
  padding: 12px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
.container:hover {
  cursor: pointer;
}
