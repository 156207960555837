@import '../../../constants/Colors.css';

.container {
  background-color: var(--color-content-box-bg);
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.2); */
}
.header {
  display: flex;
  align-content: flex-start;
  color: var(--color-header-text);
  font-size: 16px;
  padding: 4px 20px;
  justify-content: space-between;
  text-transform: uppercase;
}

.content {
  display: flex;
  flex: 1;
  width: '100%';
  flex-direction: column;
  padding: 16px;
}
