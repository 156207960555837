:root {
  --color-header-bg: #000c30;
  --color-accent: #009ae9;
  --color-layout-container-bg: #e4e5e6;
  --color-slider-bg: #000c30;
  --color-text: #708393;
  --color-content-box-bg: #fff;
  --color-light-gray: #e6e6e6;
  --color-dark-text: #424242;
  --color-light-text: #818181;
  --color-header-text: #4f5c7c;
  --color-quickguide-button: #5c95ea;
}
