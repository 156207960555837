@import '../../../../../constants/Colors.css';

.container {
  border: 1px solid var(--color-layout-container-bg);
  border-radius: 4px;
  display: flex;
  background-color: white;
  overflow: hidden;
}

.iconContainer {
  padding: 4px 12px;
  color: var(--color-dark-text);
  background-color: var(--color-light-gray);
}

.textContainer {
  flex: 1;
  background-color: white;
  opacity: 1;
}
