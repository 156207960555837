@import '../../../constants/Colors.css';

.container {
  display: flex;
  flex-direction: row;
  background-color: var(--color-header-bg);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  height: 62px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 2px 20px 0 rgba(0, 0, 0, 0.14);
}

.leftContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-left: 24px;
}

.rightContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 26px;
}

.pageTitle {
  color: var(--color-accent);
  font-size: 16px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 14px;
  padding-top: 14px;
  justify-content: space-between;
  gap: 24px;
}

.chainName {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
