@import '../../../../constants/Colors.css';

.forgotPasswordContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: var(--color-dark-text);
  font-weight: bold;
}
.forgotPasswordContainer:hover {
  cursor: pointer;
}

.separator {
  height: 1px;
  background-color: var(--color-light-gray);
}

.register {
  text-align: center;
  align-self: center;
  cursor: pointer;
  font-weight: 500;
}

.failedAttemptsWarning {
  color: orange;
  text-align: center;
  font-weight: bold;
}