@import '../../../constants/Colors.css';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 8px 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.14);
  color: var(--color-text);
  flex-direction: row;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  gap: 8px;
}

.button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.buttonDisabled {
  color: white;
  background-color: var(--color-light-gray);
  box-shadow: none;
}

.buttonDisabled:hover {
  opacity: 1;
  cursor: auto;
}

.buttonNoShadow {
  box-shadow: none;
}
