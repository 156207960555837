.ant-upload.ant-upload-drag {
  display: flex;
  border: 2px dashed var(--light-greyscale-stroke, #d7d7d7);
  border-radius: 9px;
  min-height: 180px;
  align-items: center;
}

.ant-upload-list-item-name {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-box-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 10px;

  & > span {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
}
