.badge-container {
  display: inline-flex; 
  align-items: center;
  background-color: #fde8e8; 
  color: #d32f2f; 
  font-size: 12px; 
  font-weight: 500;
  padding: 2px 6px; 
  border-radius: 10px; 
  white-space: nowrap; 
}

.badge-dot {
  width: 6px;
  height: 6px;
  background-color: #d32f2f;
  border-radius: 50%;
  margin-right: 4px;
}

.badge-text {
  display: inline-block;
}

.badgeMapper-container {
  display: inline-flex; 
  align-items: center;
  background-color: #fff3e0; 
  color: #ff9800;  
  font-size: 12px; 
  font-weight: 500;
  padding: 2px 6px; 
  border-radius: 10px; 
  white-space: nowrap; 
}

.badgeMapper-dot {
  width: 6px;
  height: 6px;
  background-color: #ff9800;  
  border-radius: 50%;
  margin-right: 4px;
}

