@import '../../../constants/Colors.css';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  font-size: 12px;
  font-weight: bold;
  color: var(--color-dark-text);
}

.inputContainer {
  border-radius: 6px;
  border: 1px solid var(--color-light-gray);
  color: var(--color-light-gray);
  background-color: white;
}
