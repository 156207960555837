@import '../../../../constants/Colors.css';

.container,
.layout {
  width: 100%;
  height: 100%;
  /* background-image: url('../../../../assets/images/welcomeBackground.png'); */
  background-size: cover;
}

.header {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  padding: 16px;
  flex-direction: row;
  justify-content: end;
  align-items: flex-end;
}

.content {
  background-color: white;
  border-radius: 12px;
  padding: 12px;
  position: absolute;
  width: 50vw;
  padding-left: 2.5%;
  padding-right: 2.5%;
  padding-bottom: 1%;
  left: 25%;
  top: 10%;
}
