@import '../../../../constants/Colors.css';

.alertContainer {
  width: 100%;
  position: absolute;
  display: flex;
  overflow: hidden;
  z-index: 999;
  justify-content: center;
  padding-top: 20%;
}

.button {
  width: 24px;
  height: 24px;
  border: 0.1px solid var(--color-light-gray);
  border-radius: 6PX;
  background-color: rgb(240, 243, 245);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  font-size: 12px;
}

.button:hover {
  background-color: rgb(228, 228, 228);
}
