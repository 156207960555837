@import '../../../../../constants/Colors.css';

input:focus ~ .floatingLabel,
input:not(:focus):valid ~ .floatingLabel {
  top: 2px;
  bottom: 10px;
  left: 8px;
  font-size: 11px;
  opacity: 0.8;
}

.inputText {
  font-size: 14px;
  outline: none;
  border: none;
  left: -8px;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  width: 100%;
  max-width: 100%;
}

.inputText:focus {
  cursor: auto;
}

.floatingLabel {
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 16px;
  transition: 0.2s ease all;
}
