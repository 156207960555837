.two-factor-container {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 20px;
}

.two-factor-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.two-factor-input:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
