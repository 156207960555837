#udfs-table {
  background-color: transparent;
}

#udfs-table .ant-table-thead > tr > th,
#udfs-table .ant-table-tbody > tr > td {
  background-color: transparent;
}

#udfs-table .ant-table-tbody > tr > td,
#udfs-table .ant-table-thead > tr > th,
#udfs-table .ant-table-content {
  border: none;
}

#udfs-table .ant-table-thead > tr > th::before {
  content: none;
}

.selected-row {
  font-weight: bold;
}
