@import '../../../../constants/Colors.css';

.button {
  height: 36px;
  width: 36px;
  border-radius: 14px;
  background-color: var(--color-quickguide-button);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.14);
}

.button:hover {
  opacity: 0.8;
  cursor: pointer;
}
