.validate-login-description {
  font-size: 0.9rem;
  margin: 1rem;
  text-align: center;
}

.loader-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.5rem 0;
}
