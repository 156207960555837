@import url(/icomoon.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-select-item-option-content {
  white-space: break-spaces;
  word-break: break-word;
}
@font-face {
  font-family: 'icomoon';
  src: url('/fonts/icomoon.eot?rw605l');
  src: url('/fonts/icomoon.eot?rw605l#iefix') format('embedded-opentype'),
    url('/fonts/icomoon.ttf?rw605l') format('truetype'),
    url('/fonts/icomoon.woff?rw605l') format('woff'),
    url('/fonts/icomoon.svg?rw605l#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bed_bed_room_sleep_furniture_icon_124423:before {
  content: '\e918';
}
.icon-signature_regular_icon_203276:before {
  content: '\e919';
}
.icon-hotel-signal-of-capital-letter_icon-iconscom_70710:before {
  content: '\e91a';
}
.icon-appointment-calendar-communication-contact-date-iconoteka-personal_107668:before {
  content: '\e91b';
}
.icon-fin-available-calendar-date-day-event-iconoteka-schedule_107694:before {
  content: '\e91c';
}
.icon-check_mark_ok_good_approve_safe_icon-iconscom_59987:before {
  content: '\e91d';
}
.icon-mail_message_envelope_letter_icon_192475:before {
  content: '\e912';
}
.icon-llegada-available-calendar-date-day-event-iconoteka-schedule_107694:before {
  content: '\e91e';
}
.icon-available-calendar-date-day-event-iconoteka-schedule_107694:before {
  content: '\e91f';
}
.icon-user_people_male_avatar_man_icon_196478-copia:before {
  content: '\e920';
}
.icon-business-card-finance-iconoteka-membership-payment_107686:before {
  content: '\e921';
}
.icon-share:before {
  content: '\e901';
}
.icon-information-1:before {
  content: '\e990';
}
.icon-next:before {
  content: '\e90f';
}
.icon-birthday-cake:before {
  content: '\e909';
}
.icon-calendar:before {
  content: '\e906';
}
.icon-close:before {
  content: '\e915';
}
.icon-accept:before {
  content: '\e914';
}
.icon-checked:before {
  content: '\e90c';
}
.icon-credit-card:before {
  content: '\e908';
}
.icon-delete:before {
  content: '\e90e';
}
.icon-download:before {
  content: '\e913';
}
.icon-fat-boy-smiling:before {
  content: '\e903';
}
.icon-flag:before {
  content: '\e907';
}
.icon-hotel1:before {
  content: '\e917';
}
.icon-back:before {
  content: '\e910';
}
.icon-location:before {
  content: '\e904';
}
.icon-moon:before {
  content: '\e905';
}
.icon-hotel-not:before {
  content: '\e916';
}
.icon-pen:before {
  content: '\e90d';
}
.icon-phone:before {
  content: '\e90b';
}
.icon-pin:before {
  content: '\e911';
}
.icon-search:before {
  content: '\e900';
}
.icon-sex:before {
  content: '\e90a';
}
.icon-child:before {
  content: '\e902';
}
.icon-upload:before {
  content: '\912';
}

:root {
  --color-header-bg: #000c30;
  --color-accent: #009ae9;
  --color-layout-container-bg: #e4e5e6;
  --color-slider-bg: #000c30;
  --color-text: #708393;
  --color-content-box-bg: #fff;
  --color-light-gray: #e6e6e6;
  --color-dark-text: #424242;
  --color-light-text: #818181;
  --color-header-text: #4f5c7c;
  --color-quickguide-button: #5c95ea;
}

.WelcomeButton_container__1Fs5r {
  background-color: var(--color-accent);
  flex: 1 1;
  padding: 12px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
.WelcomeButton_container__1Fs5r:hover {
  cursor: pointer;
}

.WelcomeInput_container__wztvQ {
  border: 1px solid var(--color-layout-container-bg);
  border-radius: 4px;
  display: flex;
  background-color: white;
  overflow: hidden;
}

.WelcomeInput_iconContainer__1AvFW {
  padding: 4px 12px;
  color: var(--color-dark-text);
  background-color: var(--color-light-gray);
}

.WelcomeInput_textContainer__1GEmY {
  flex: 1 1;
  background-color: white;
  opacity: 1;
}

.WelcomeLayout_container__1-gP_,
.WelcomeLayout_layout__31ZsO {
  width: 100%;
  height: 100%;
  /* background-image: url('../../../../assets/images/welcomeBackground.png'); */
  background-size: cover;
}

.WelcomeLayout_header__psK-f {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  padding: 16px;
  flex-direction: row;
  justify-content: end;
  align-items: flex-end;
}

.WelcomeLayout_content__3y0re {
  background-color: white;
  border-radius: 12px;
  padding: 12px;
  position: absolute;
  width: 50vw;
  padding-left: 2.5%;
  padding-right: 2.5%;
  padding-bottom: 1%;
  left: 25%;
  top: 10%;
}

.HoverWrapper_view__1yZIJ {
  flex-direction: row;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.HoverWrapper_view__1yZIJ:hover {
  cursor: pointer;
}

.Login_forgotPasswordContainer__k2XxI {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: var(--color-dark-text);
  font-weight: bold;
}
.Login_forgotPasswordContainer__k2XxI:hover {
  cursor: pointer;
}

.Login_separator__24M6U {
  height: 1px;
  background-color: var(--color-light-gray);
}

.Login_register__FALtD {
  text-align: center;
  align-self: center;
  cursor: pointer;
  font-weight: 500;
}

.Login_failedAttemptsWarning__2QRl3 {
  color: orange;
  text-align: center;
  font-weight: bold;
}
.Loading_ant-spin-dot-item__3pzFl {
  background-color: red;
}

.BaseTable_container__1E6yE {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.BaseTable_headerTitleContainer__1JQia {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}
.BaseTable_headerTitle__ikyhY {
  font-size: 16px;
}
.BaseTable_actionButtonContainer__H3erl {
  display: flex;
  align-items: center;
}
.BaseTable_actionButton__2cMHI {
  margin-right: 10px;
}
.BaseTable_searchPopup__LJUyt {
  padding: 8px;
}
.BaseTable_searchInput__UpDt8 {
  width: 188px;
  margin-bottom: 8px;
  display: block;
}
.BaseTable_searchButton__2HBuX {
  width: 90px;
}

.CustomButton_button__jrBNp {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 8px 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.14);
  color: var(--color-text);
  flex-direction: row;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  grid-gap: 8px;
  gap: 8px;
}

.CustomButton_button__jrBNp:hover {
  opacity: 0.8;
  cursor: pointer;
}

.CustomButton_buttonDisabled__2XEFd {
  color: white;
  background-color: var(--color-light-gray);
  box-shadow: none;
}

.CustomButton_buttonDisabled__2XEFd:hover {
  opacity: 1;
  cursor: auto;
}

.CustomButton_buttonNoShadow__23smL {
  box-shadow: none;
}

.DefaultLayout_container__a8VwE {
  width: 100%;
  height: 100%;
}

.DefaultLayout_layout__3VDTm {
  min-height: 100vh;
}

.DefaultLayout_layoutHeader__3E00Z {
  display: flex;
  background-color: var(--color-header-bg);
  padding: 0px;
}

.DefaultLayout_layoutSider__2cVf_ {
  color: white;
  background-color: var(--color-slider-bg);
  border-right: 1px solid #ddd;
}

.DefaultLayout_menuItem__1-Wel {
  padding: 0px;
  margin-top: -4px;
  color: white;
  background-color: var(--color-accent);
  font-weight: bold;
  font-size: 14px;
  border-radius: 0px;
  /* text-transform: uppercase; */
}

.DefaultLayout_menuItem__1-Wel :hover {
  color: var(--color-accent);
}


.DefaultLayout_subSiderItem__28xMu {
  background-color: var(--color-slider-bg);
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 36px;
  padding-bottom: 20px;
  padding-top: 8px;
  font-size: 12px;
  min-height: 24px;
}

.DefaultLayout_siderItem__38uFF {
  background-color: var(--color-slider-bg);
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  padding: 10px 16px;
  font-size: 14px;
  min-height: 42px;
}

.DefaultLayout_siderItem__38uFF :hover {
  color: var(--color-accent);
}

.DefaultLayout_siderItemActive__1kcw2 {
  color: var(--color-accent);
}

.DefaultLayout_layoutContent__3yC7E {
  color: #999;
  background-color: #f2f7fb;
}

.DefaultLayout_pageTitleContainer__32R-J {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 32px;
  padding-top: 16px;
}

.DefaultLayout_pageTitle__1fQ4M {
  color: var(--color-header-text);
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}

.DefaultLayout_icon__1JCm7 {
  max-width: 14px;
  height: auto;
  object-fit: cover;
}

.DefaultLayout_screenBlocker__3zLna {
  background-color: rgba(0, 0, 0, 0.45);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  z-index: 9;
}

.DefaultLayout_disabledLink__6EfsE {
  pointer-events: none;
}

.DefaultLayout_centeredText__3JF_P {
    text-align: 'center'
}

.DefaultLayout_modalButton__qbkJ2 {
  display: flex;
  justify-content: right;
}

.DefaultLayout_closeButton__2LbRu {
  border-radius: 5px;
  background-color: var(--color-accent);
  border-color: var(--color-accent);
  margin-top: 20px;
}

.DefaultHeader_container__3Zhn5 {
  display: flex;
  flex-direction: row;
  background-color: var(--color-header-bg);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  height: 62px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 2px 20px 0 rgba(0, 0, 0, 0.14);
}

.DefaultHeader_leftContainer__3UY7b {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-left: 24px;
}

.DefaultHeader_rightContainer__iELNl {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 26px;
}

.DefaultHeader_pageTitle__3VBEa {
  color: var(--color-accent);
  font-size: 16px;
}

.DefaultHeader_buttonsContainer__7Kix2 {
  display: flex;
  flex-direction: row;
  padding-bottom: 14px;
  padding-top: 14px;
  justify-content: space-between;
  grid-gap: 24px;
  gap: 24px;
}

.DefaultHeader_chainName__1n_qT {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.QuickguideButton_button__3-3Zf {
  height: 36px;
  width: 36px;
  border-radius: 14px;
  background-color: var(--color-quickguide-button);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.14);
}

.QuickguideButton_button__3-3Zf:hover {
  opacity: 0.8;
  cursor: pointer;
}

.ContentBox_container__2Z-VJ {
  background-color: var(--color-content-box-bg);
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.2); */
}
.ContentBox_header__1mBts {
  display: flex;
  align-content: flex-start;
  color: var(--color-header-text);
  font-size: 16px;
  padding: 4px 20px;
  justify-content: space-between;
  text-transform: uppercase;
}

.ContentBox_content__1Acly {
  display: flex;
  flex: 1 1;
  width: '100%';
  flex-direction: column;
  padding: 16px;
}

.AppButton_buttonContainer__1VI1j {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-accent);
  border-radius: 10px;
  padding: 4px 26px;
  color: white;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.14);
  grid-gap: 8px;
  gap: 8px;
}

.AppButton_buttonContainer__1VI1j:hover {
  opacity: 0.8;
  cursor: pointer;
}

.ColorInput_container__uujFb {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 12px;
  align-items: center;
}

.ColorInput_textContainer__2jjLi {
  flex: 1 1;
  flex-direction: column;
}

.ColorInput_title__o1U7U {
  font-size: 12px;
  font-weight: bold;
  color: var(--color-dark-text);
}

.ColorInput_description__1EdFy {
  font-size: 12px;
  color: var(--color-light-text);
}

.ColorInput_colorContainer__3eiMU {
  display: flex;
  flex: 0.8 1;
  flex-direction: row;
  height: 36px;
  border: 1px solid darkgray;
  border-radius: 6px;
  overflow: hidden;
}

.ColorInput_color__1PYV4 {
  flex: 1 1;
}

.ColorInput_pickerIcon__23NH5 {
  width: 36px;
  background-color: var(--color-layout-container-bg);
  border-left: 1px solid darkgray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.ImageInput_container__3y0wO {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 12px;
  align-items: center;
}

.ImageInput_textContainer__3aTeg {
  flex: 1 1;
  flex-direction: column;
}

.ImageInput_title__ZsFi2 {
  font-size: 12px;
  font-weight: bold;
  color: var(--color-dark-text);
}
.ImageInput_description__1VkYN {
  font-size: 12px;
  color: var(--color-light-text);
}

.ImageInput_colorContainer__loAC3 {
  display: flex;
  flex: 0.8 1;
  flex-direction: row;
  height: 36px;
  border: 1px solid darkgray;
  border-radius: 6px;
  overflow: hidden;
}

.ImageInput_color__10GLD {
  flex: 1 1;
}

.ImageInput_pickerIcon__3uw5L {
  width: 36px;
  background-color: var(--color-layout-container-bg);
  border-left: 1px solid darkgray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.HotelList_alertContainer__3LrAp {
  width: 100%;
  position: absolute;
  display: flex;
  overflow: hidden;
  z-index: 999;
  justify-content: center;
  padding-top: 20%;
}

.HotelList_button__2U3ii {
  width: 24px;
  height: 24px;
  border: 0.1px solid var(--color-light-gray);
  border-radius: 6PX;
  background-color: rgb(240, 243, 245);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  font-size: 12px;
}

.HotelList_button__2U3ii:hover {
  background-color: rgb(228, 228, 228);
}

.AppTextInput_container__2jRmt {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.AppTextInput_header__1VJdT {
  font-size: 12px;
  font-weight: bold;
  color: var(--color-dark-text);
}

.AppTextInput_inputContainer__DFJyO {
  border-radius: 6px;
  border: 1px solid var(--color-light-gray);
  color: var(--color-light-gray);
  background-color: white;
}

.badge-container {
  display: inline-flex; 
  align-items: center;
  background-color: #fde8e8; 
  color: #d32f2f; 
  font-size: 12px; 
  font-weight: 500;
  padding: 2px 6px; 
  border-radius: 10px; 
  white-space: nowrap; 
}

.badge-dot {
  width: 6px;
  height: 6px;
  background-color: #d32f2f;
  border-radius: 50%;
  margin-right: 4px;
}

.badge-text {
  display: inline-block;
}

.badgeMapper-container {
  display: inline-flex; 
  align-items: center;
  background-color: #fff3e0; 
  color: #ff9800;  
  font-size: 12px; 
  font-weight: 500;
  padding: 2px 6px; 
  border-radius: 10px; 
  white-space: nowrap; 
}

.badgeMapper-dot {
  width: 6px;
  height: 6px;
  background-color: #ff9800;  
  border-radius: 50%;
  margin-right: 4px;
}


#udfs-table {
  background-color: transparent;
}

#udfs-table .ant-table-thead > tr > th,
#udfs-table .ant-table-tbody > tr > td {
  background-color: transparent;
}

#udfs-table .ant-table-tbody > tr > td,
#udfs-table .ant-table-thead > tr > th,
#udfs-table .ant-table-content {
  border: none;
}

#udfs-table .ant-table-thead > tr > th::before {
  content: none;
}

.selected-row {
  font-weight: bold;
}

.DoorOpeningMaintenance_alertContainer__76cpj {
  width: 100%;
  position: absolute;
  display: flex;
  overflow: hidden;
  z-index: 999;
  justify-content: center;
  padding-top: 20%;
}

.DoorOpeningMaintenance_button__1QkC6 {
  width: 24px;
  height: 24px;
  border: 0.1px solid var(--color-light-gray);
  background-color: rgb(240, 243, 245);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  font-size: 12px;
}

.DoorOpeningMaintenance_button__1QkC6:hover {
  background-color: rgb(228, 228, 228);
}

.remove-padding .ant-table-tbody {
  padding: 0 !important;
}
.remove-padding .ant-table-tbody > tr > td {
  padding: 0 !important;
}

input:focus ~ .EditableInput_floatingLabel__2-sFX,
input:not(:focus):valid ~ .EditableInput_floatingLabel__2-sFX {
  top: 2px;
  bottom: 10px;
  left: 8px;
  font-size: 11px;
  opacity: 0.8;
}

.EditableInput_inputText__3crsm {
  font-size: 14px;
  outline: none;
  border: none;
  left: -8px;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  width: 100%;
  max-width: 100%;
}

.EditableInput_inputText__3crsm:focus {
  cursor: auto;
}

.EditableInput_floatingLabel__2-sFX {
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 16px;
  transition: 0.2s ease all;
}

.dynamicForm .ant-table-tbody {
  padding: 0 !important;
}
.dynamicForm .ant-table-tbody > tr > td {
  padding: 0 !important;
}

:root {
  --color-header-bg: #000c30;
  --color-accent: #009ae9;
  --color-layout-container-bg: #e4e5e6;
  --color-slider-bg: #000c30;
  --color-text: #708393;
  --color-content-box-bg: #fff;
  --color-light-gray: #e6e6e6;
  --color-dark-text: #424242;
  --color-light-text: #818181;
  --color-header-text: #4f5c7c;
  --color-quickguide-button: #5c95ea;
}

/* CustomSelector.css */

.ant-radio-button-wrapper::before {
    content: none !important;
}
.ant-upload.ant-upload-drag {
  display: flex;
  border: 2px dashed #d7d7d7;
  border: 2px dashed var(--light-greyscale-stroke, #d7d7d7);
  border-radius: 9px;
  min-height: 180px;
  align-items: center;
}

.ant-upload-list-item-name {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-box-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;

  & > span {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
}

.sortable-item {
  &:hover {
    cursor: -webkit-grab;
    cursor: grab;
  }
}

.ant-table-tbody > tr > td {
    border-bottom: none;
    transition: background 0.3s;
}

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: none;
}
.specific-input-wrapper .ant-input {
    text-align: right;
  }
  
.ant-card-body {
    padding: 0px;
}
.ant-picker.ant-picker-range {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; 
}
.ant-card-body {
    padding: 0px;
}
.ant-picker.ant-picker-range {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; 
}
.BillingInfoBox_titleWithError__2VAKt {
    color: rgba(172, 21, 24, 1) !important;
}
.BillingStatusBox_container__Dcq4U {
  background-color: #EAECF780;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 18px;
  border-left: 10px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 414px;
}

.BillingStatusBox_content__2NjA4 {
  display: flex;
  flex-direction: row;
}

.BillingStatusBox_box__rpDv7 {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 60px;
}
.two-factor-container {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  justify-content: center;
  margin-top: 20px;
}

.two-factor-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.two-factor-input:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.validate-login-description {
  font-size: 0.9rem;
  margin: 1rem;
  text-align: center;
}

.loader-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.5rem 0;
}

.App_app__1kX79 {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

