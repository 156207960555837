.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.headerTitleContainer {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}
.headerTitle {
  font-size: 16px;
}
.actionButtonContainer {
  display: flex;
  align-items: center;
}
.actionButton {
  margin-right: 10px;
}
.searchPopup {
  padding: 8px;
}
.searchInput {
  width: 188px;
  margin-bottom: 8px;
  display: block;
}
.searchButton {
  width: 90px;
}
